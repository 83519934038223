import Icon from './Icon';
import './ListItemSwitchGroup.scss';



import React, { Component, useEffect, useRef, useState } from 'react';
import Switch from './Switch';
import Check from './Check';
import ListItem from './ListItem';
 
     

export   function ListItemSwitchGroup(props) { 
   
  let {label, propertyName, renderParentSwitch, onlyOneChildActive, className } = props;
  
  const [updater, setUpdater]  = useState(0);

  const [data, setData] = useState(props.data);


  useEffect(() => {  
    setData(props.data); 
  }, [props.data]);

  const onChange=(switchValue)=>{
    data[propertyName]=switchValue;  

    console.log('ListItemSwitchGroup onChange', propertyName,  data[propertyName]);
    // if(!switchValue){ 
    //   children.forEach(c=>{
    //     c.value=false;
    //   });
    // }

    if(props.onChange){
      props.onChange(data, data[propertyName]);
    }

    // setUpdater(updater+1);
    setData({...data});
  }


  const onChildChange=( childPropertyName, childValue)=>{
    console.log('onChildChange', data, childPropertyName);
    let children = React.Children.toArray(props.children);
     

    if(!childValue && onlyOneChildActive && !children.filter(c=>c.props.propertyName!=childPropertyName && data[c.props.propertyName]).length){
        childValue = true;
        console.log('all false',childPropertyName, data[childPropertyName], childValue);
    }

    data[childPropertyName] = childValue;

    if(renderParentSwitch && !data[propertyName]){
      data[childPropertyName] = false;
      // setUpdater(updater+1);
      
      setData({...data});
      return; 
    }

   
    if(onlyOneChildActive && childValue){
      children.forEach(c=>{
        if(c.props.propertyName!=childPropertyName && data[c.props.propertyName]){
          data[c.props.propertyName] = false;
        }
      }); 
    }
   

    console.log('onChildChange',childValue, data[childPropertyName]);
    //childData[childPropertyName]=childValue; 
    if(props.onChange){
      props.onChange(data, data[childPropertyName]);
    } 

    setData({...data});
    // setUpdater(updater+1);
  }

  const updateChildrenWithProps = React.Children.toArray(props.children).map( 
    (child, i) => { 
      // let cdata =  data;
      if(propertyName){
        data[child.props.propertyName] = data[propertyName]?data[child.props.propertyName]:false;
      }
      // console.log(' child.props.data[child.props.propertyName]', cdata[child.props.propertyName]);
      return React.cloneElement(child, {...child.props, data:data, propertyName:child.props.propertyName,  onChange:(childData, childValue)=>onChildChange( child.props.propertyName, childValue) } );
    }
  );

  return ( 
      <div  className={'list-item-switch-group '+(className || '')} >
         {renderParentSwitch && 
          <ListItem label={label}
            switch={true}   
            value={data[propertyName]} 
            onChange={(switchValue) => onChange(switchValue) } />
         }
         {props.children && renderParentSwitch ? 
              <div className={'list-item-switch-group-children' + (data[propertyName] ?' enabled ':' disabled ')}>
                {updateChildrenWithProps}
              </div>
              :
              updateChildrenWithProps
         }
      </div>
  );
}


export   function ListItemSwitchGroupItem(props) { 
   
  let {data, label, propertyName, className ,showChildrenDisabled} = props;
   
  // console.log('ListItemSwitchGroupItem', data[propertyName]);

  const onChange=(switchValue)=>{
     console.log('ListItemSwitchGroupItem onChange', switchValue);
  
    data[propertyName]=switchValue; 
    if(props.onChange){
      props.onChange(data, data[propertyName]);
    } 
  }


  return (  <><ListItem label={label}
                switch 
                value={data[propertyName]} 
                onChange={(switchValue) => onChange(switchValue) } /> 
                {showChildrenDisabled ? <div className="group-item-children" disabled={!data[propertyName]}>{ props.children}</div>:null}
                {!showChildrenDisabled && data[propertyName] ?  props.children : null} 
                </>
            
  );
}


