import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import tmo from '../../../tmo/tmo.lib';
import Button from '../../../components/basic/Button';
import IconButton from '../../../components/basic/IconButton';
import './ViewPage.scss';
import './ViewPage.print.scss';
import Icon from '../../../components/basic/Icon';

function ViewPage(props) {
    const {
        onLoad,
        setData,
        data,
        api,
        navigations,
        onExport,
        onReSync,
        pageTitle,
        leftBarContent,
        leftBarTitle,
        rightBarContent,
        rightBarTitle,
        hideActions,
        customId,
        disableDelete,
        disableEdit,
    } = props;

    const [refresher, setRefresher] = useState(0);
    const params = useParams();
    const [rightPanelOpened, setRightPanelOpened] = useState(false);
    const [leftPanelOpened, setLeftPanelOpened] = useState(false);
    const navigate = useNavigate();
    const [canLoad , setCanLoad]  = useState(false);

    const className =
        'view-page ' +
        (props.className || '') +
        ' ' +
        (rightBarContent ? ' has-rightbar ' : ' ') +
        (leftBarContent ? ' has-leftbar ' : ' ') +
        (leftPanelOpened ? ' leftbar-open ' : ' ') +
        (rightPanelOpened ? ' rightbar-open ' : ' ');

    const deleteRecordClicked = () => {
        tmo.ui.global.showConfirmPopup({
            popupType: 'delete',
            onDone: deleteRecord,
            contentTitle: 'You are deleting this record',
            contentDetail: (
                <div>
                    <div className="paragraph">This record will be deleted from system. </div>
                    <div className="paragraph">
                        <label>Outcome:</label> You won't be able to access any data belongs to this
                        record.
                    </div>
                </div>
            ),
        });
    };

    const exportData = () => {
        if (onExport) {
            onExport();
        }
    };


    const resyncData = () => {
        if (onReSync) {
            onReSync();
        }
    };
    

    const rollbackRecordClicked = async () => {
        await api.rollback({ id: params.id });
        setRefresher(refresher + 1);
    };

    const deleteRecord = async () => {
        let result = await api.remove({ id: params.id });
        setRefresher(refresher + 1);

        if (result) {
            tmo.ui.global.showConfirmPopup({
                popupType: 'deleteSucceeded',
                onDone: navigations.gotoList,
                onCancel: navigations.gotoList,
            });
        } else {
            tmo.ui.global.showConfirmPopup({ popupType: 'deleteFailed' });
        }
    };

    const middleContent = () => {
        return (
            <>
                <div className="main-content">{props.children}</div>
            </>
        );
    };

    const leftContent = () => {
        return <div className="leftbar sidebar">{leftBarContent}</div>;
    };

    const rightContent = () => {
        return <div className="rightbar sidebar">{rightBarContent}</div>;
    };

    const getContent = () => {
        return (
            <>
                {leftBarContent && leftContent()}
                <div className="info">{middleContent()}</div>
                {rightBarContent && rightContent()}
            </>
        );
    };

    const leftOpenerClicked = (e) => {
        setRightPanelOpened(false);
        setLeftPanelOpened(true);
        tmo.ui.global.disablePageScroll();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const rightOpenerClicked = (e) => {
        setRightPanelOpened(true);
        setLeftPanelOpened(false);
        tmo.ui.global.disablePageScroll();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const closeAllPanels = (e) => {
        setRightPanelOpened(false);
        setLeftPanelOpened(false);
        tmo.ui.global.enablePageScroll();
    };

    useEffect(() => {
        
        api.get({ id: params.id }).then((d) => {
            if (onLoad) {
                onLoad({ data: d });
            }
            setData(d);
            setCanLoad(true);
        });
    }, [refresher]);

    return (
        <div className={className}>
            {data.deleted && (
                <div className="deleted-message">
                    Record has been deleted on {data.deleted_at}. You can rollback this record by
                    clicking:{' '}
                    <Button
                        label="ROLLBACK CUSTOMER"
                        icon="settings_backup_restore"
                        size="small"
                        noBorder
                        onClick={rollbackRecordClicked}
                    />
                </div>
            )}
            {canLoad &&
            <div className="view-page-header">
                <div className="title">
                    <IconButton
                        name="arrow_back"
                        tooltip={'Go back'}
                        className="go-back"
                        onClick={() => navigate(-1)}
                    />
                    <span>{pageTitle || 'Report'}</span>
                </div>
                {!hideActions && (
                    <div className="right-side">
                        <Button
                            label="DOWNLOAD REPORT"
                            id="print-button"
                            icon="download"
                            size="small"
                            noBorder
                            onClick={exportData}
                        />

                        {onReSync && <Button
                            label="RE-SYNC"
                            id="print-button"
                            icon="sync"
                            size="small"
                            noBorder
                            onClick={resyncData}
                        />}
                        {!disableEdit && (
                            <Button
                                label="EDIT RECORD"
                                icon="edit"
                                size="small"
                                noBorder
                                onClick={() => navigations.gotoEdit(customId || params.id)}
                            />
                        )}
                        {!data.deleted && !disableDelete && (
                            <Button
                                label="DELETE RECORD"
                                icon="delete"
                                size="small"
                                noBorder
                                onClick={deleteRecordClicked}
                            />
                        )}
                    </div>
                )}
            </div>}
    

            <div className="view-page-panel-openers-bg" onClick={closeAllPanels} />
            
            <div className="view-page-panel-openers">
                <div className="view-page-panel-opener-left" onClick={leftOpenerClicked}>
                    <IconButton name="left_panel_open" /> {leftBarTitle}
                </div>
                <div className="view-page-panel-opener-placeholder" />
                <div className="view-page-panel-opener-right" onClick={rightOpenerClicked}>
                    {rightBarTitle} <IconButton name="right_panel_open" />
                </div>
            </div>

            {!canLoad && (
                
                <div className="loading-content">
                    <Icon name="cached" />
                    <div className="loading-content-title"> Loading ... </div>
                    <div className="loading-content-desc">
                        Please wait while we are loading the data.
                    </div>
                </div>
            
            )}
            {canLoad && getContent()}
            <div className="view-page-footer" />
        </div>
    );
}

export default ViewPage;
